import React from 'react';
import { useIntl } from 'react-intl';
import { useToast } from '@repo/design-system-kit';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import {
  CertificateDownload as BaseCertificateDownload,
  type CertificateFormValues,
} from '@repo/domain-kit/account-management';
import { useCreatePdfCertificate } from 'qonto/react/hooks/use-create-pdf-certificate';
import { usePdfDownload } from 'qonto/react/hooks/use-fetch-pdf';

interface OrganizationManager {
  organization: {
    mainAccount: { id: string };
    legalCountry: string;
  };
}

export function CertificateDownload(): React.ReactNode {
  const { formatMessage } = useIntl();
  const { showToast } = useToast();
  const organizationManager = useEmberService('organization-manager') as OrganizationManager;
  const organization = organizationManager.organization;

  const accountId = organization.mainAccount.id;
  const orgLegalCountry = organization.legalCountry;

  const { mutateAsync: createCertificate, isPending: isCreatingCertificate } =
    useCreatePdfCertificate();
  const { isPending: isDownloadingPdf } = usePdfDownload();

  const isBusy = isCreatingCertificate || isDownloadingPdf;

  const handleSubmit = async (values: CertificateFormValues): Promise<void> => {
    if (!accountId) {
      showToast({
        text: formatMessage({ id: 'requests.mileage.steps.journey.errors.something-wrong' }),
        type: 'error',
      });
      return;
    }

    await createCertificate({
      accountId,
      locale: values.locale,
      withBalance: values.withBalance,
    });
  };

  return (
    <BaseCertificateDownload
      countryCode={orgLegalCountry}
      isLoading={isBusy}
      onSubmit={handleSubmit}
    />
  );
}
