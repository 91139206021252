import { useCallback, useEffect, useMemo, useRef, useState, type ReactNode } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  type ColumnDef,
  createColumnHelper,
  flexRender,
  type ExpandedState,
  type Header,
  type CellContext,
} from '@tanstack/react-table';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import dayjs from 'dayjs';
import { AnimatedCell, type AnimatedCellProps } from '@repo/domain-kit/cashflow';
import { useQueryClient } from '@tanstack/react-query';
import { useToast } from '@repo/design-system-kit';
import { ArrowRight } from 'qonto/react/assets/icons/arrow-right';
import { ArrowDown } from 'qonto/react/assets/icons/arrow-down';
import { IconCog } from 'qonto/react/assets/icons/cash-flow-categories/icon-cog';
import { setTransactionsFilterByCategories } from 'qonto/react/components/cash-flow/utils/transactions-filter.ts';
import {
  cashFlowSidePanelManager,
  type CashFlowSidePanelPayload,
} from 'qonto/react/contexts/cash-flow-sidepanel-context';
import { useOrganizationManager } from 'qonto/react/hooks/use-organization-manager';
import { useOrganizationNavigation } from 'qonto/react/shared/hooks/use-organization-navigation';
import type { CashFlowCategoriesForecastsPreview } from 'qonto/react/models/cash-flow-categories-forecasts-preview';
import { useAutofillCashflowForecast } from 'qonto/react/hooks/use-autofill-cashflow-forecast.ts';
import { type CashflowForecastEntry } from 'qonto/react/models/cash-flow-forecast-entry';
import type { LabelTableInterval } from 'qonto/react/components/cash-flow/models/labels-cashflow-display.ts';
import {
  generateLoadingCategoriesData,
  generateLoadingHeaders,
} from '../../utils/generate-loading-data';
import { TableCell } from '../shared/table-cell';
import type {
  CategoriesTableColumn,
  CategoriesTableRow,
} from '../../models/categories-table-display';
import { MemoizedTableCell } from '../shared/table-cell/table-cell';
import { useFirstTimeExperience } from '../../hooks/use-first-time-experience';
import { CategoryCell } from './category-cell';
import { BalanceCell } from './balance-cell';
import styles from './styles.strict-module.css';
import { ProjectedCellHeader } from './projected-cell';
import { AnimatedRow } from './animated-row';
import { FlashForecastConfirmationPopup } from './flash-forecast-popup/flash-forecast-confirmation-popup';

interface TableCellContext extends CellContext<CategoriesTableRow, CategoriesTableColumn[]> {
  rowIndex: number;
  columnIndex: number;
}

interface TableMeta {
  selectedCategoriesIds: string | undefined;
  selectedIntervalStart: number | undefined;
  selectedFlowType: string | undefined;
}

interface GenerateForecastAmountParams {
  isFirstTimeExperience: boolean | undefined;
  hasFirstTimeExperieceForecastBeenSet: boolean | undefined;
  forecast: CashflowForecastEntry | undefined;
}

// In order to include catgeories row & flow type header row
type CustomRow = Omit<CategoriesTableRow, 'type'> & {
  type: CategoriesTableRow['type'] | 'flowType';
};

const ROW_ANIMATION_DURATION_MS = 500;

// CSS-based selection approach: These functions check if a cell is logically selected
// but don't trigger re-renders. The actual highlighting is done with CSS selectors.
const isRowSelected = (
  row: Pick<CustomRow, 'id' | 'type' | 'flowType'>,
  selectedCategoriesIds: string | undefined,
  selectedFlowType: string | undefined,
  isFlowSelected = false
): boolean => {
  if (selectedCategoriesIds === row.id) {
    return true;
  }

  if (row.type === 'flowType' && isFlowSelected && selectedFlowType === row.flowType) {
    return true;
  }

  if (
    row.type === 'uncategorized' &&
    selectedCategoriesIds === '' &&
    selectedFlowType === row.flowType
  ) {
    return true;
  }

  return false;
};

const normalizeToStartOfDay = (timestamp: number | undefined): string | undefined => {
  if (!timestamp) return undefined;
  return dayjs(timestamp).format('YYYY-MM-DD');
};

const isCellSelected = (
  row: Pick<CustomRow, 'id' | 'type' | 'flowType'>,
  meta: TableMeta,
  intervalStart: number | undefined,
  isFlowSelected = false
): boolean => {
  const isSelectedCategory = isRowSelected(
    row,
    meta.selectedCategoriesIds,
    meta.selectedFlowType,
    isFlowSelected
  );
  const normalizedSelectedInterval = normalizeToStartOfDay(meta.selectedIntervalStart);
  const normalizedInterval = normalizeToStartOfDay(intervalStart);
  const isSelectedInterval = normalizedSelectedInterval === normalizedInterval;
  return isSelectedCategory && isSelectedInterval;
};

/**
 * Generates the forecast object considering the first time experience
 * This is done because on the FTE forecast values are displayed as 0 when:
 * - The forecast has not been set
 * - The flow type is not inflows
 * - It is not the first row (case for FTE Flash forecast only)
 * @param isFirstTimeExperience - Whether the user is in the first time experience
 * @param hasFirstTimeExperieceForecastBeenSet - Whether the first time experience forecast has been set
 * @param forecast - The forecast object
 * @returns The forecast object or null if there's no forecast
 */
const generateForecastAmount = ({
  isFirstTimeExperience,
  hasFirstTimeExperieceForecastBeenSet,
  forecast,
}: GenerateForecastAmountParams): CashflowForecastEntry | null => {
  if (!forecast) return null;
  if (!isFirstTimeExperience) return forecast;

  const emptyForecast = { ...forecast, amount: { value: '0.00', currency: 'EUR' } };

  // Should display the FTE forecast only for inflows and after the Forecast has been set
  // Otherwise, cells should display 0
  if (!hasFirstTimeExperieceForecastBeenSet) return emptyForecast;

  return forecast;
};

const buildTable = (
  data: CategoriesTableRow[],
  sums: CategoriesTableColumn[],
  isLoading: boolean,
  numberOfColumns: number,
  headerLabel: string,
  onCellNavigation: (params: {
    rowIndex: number;
    columnIndex: number;
    direction: 'up' | 'down' | 'left' | 'right';
    enterEditMode: boolean;
  }) => void,
  selectedCategoriesIds: string | undefined,
  selectedIntervalStart: number | undefined,
  selectedFlowType: string | undefined,
  isFlowSelected: boolean,
  closeSidepanel: () => void,
  setConfirmFlashForecastForCategory: (categoryId?: string) => void,
  setDisplayForecastPreviewForCategory?: (value: string | undefined) => void,
  displayForecastPreviewForCategory?: string,
  isForecastEditingEnabled?: boolean,
  showProjectedForecast?: boolean,
  flashForecastPreviewData?: CashFlowCategoriesForecastsPreview,
  onForecastEntryUpdate?: () => void,
  onViewTransactions?: (payload: CashFlowSidePanelPayload) => void,
  isFirstTimeExperience?: boolean,
  hasFirstTimeExperieceForecastBeenSet?: boolean
): {
  tableData: CategoriesTableRow[];
  columns: ColumnDef<CategoriesTableRow>[];
  meta: TableMeta;
} => {
  const headersData = isLoading
    ? generateLoadingHeaders(numberOfColumns)
    : Array.from({ length: numberOfColumns }, (_, index) => String(index));

  const tableData = isLoading ? generateLoadingCategoriesData(numberOfColumns) : data;
  const columnHelper = createColumnHelper<CategoriesTableRow>();
  const columns = [
    columnHelper.accessor('name', {
      header: headerLabel,
      cell: info => (
        <CategoryCell
          canExpand={info.row.getCanExpand()}
          color={info.row.original.color}
          icon={info.row.original.icon}
          isExpanded={info.row.getIsExpanded()}
          name={info.getValue()}
          onExpandToggle={info.row.getToggleExpandedHandler()}
          type={info.row.original.type}
        />
      ),
    }),
    ...headersData.map((col, index) =>
      columnHelper.accessor('columns', {
        id: col,
        header: info => {
          const meta = info.table.options.meta as TableMeta;
          const flowType = data[0]?.flowType ?? 'inflows';

          // Allows to keep the flow type header highlighted across intervals
          const isSelectedCell = isCellSelected(
            { id: flowType, type: 'flowType', flowType },
            meta,
            sums[index]?.interval?.start,
            isFlowSelected
          );

          const forecast = generateForecastAmount({
            isFirstTimeExperience,
            hasFirstTimeExperieceForecastBeenSet,
            forecast: sums[index]?.forecast,
          });

          return (
            <BalanceCell
              amount={sums[index]?.amount}
              categories={data}
              closeSidepanel={closeSidepanel}
              flowType={flowType}
              forecast={forecast}
              interval={sums[index]?.interval}
              isFlowSelected
              isHeaderCell
              isSelectedCell={isSelectedCell}
              key={`header-${sums[index]?.interval?.start}-${flowType}-${isSelectedCell}`}
              onViewTransactions={onViewTransactions}
              projectedAmount={sums[index]?.projectedAmount}
              showProjectedForecast={showProjectedForecast}
            />
          );
        },
        cell: info => {
          const { rowIndex, columnIndex } = info as TableCellContext;
          const meta = info.table.options.meta as TableMeta;

          const isUncategorized = info.row.original.type === 'uncategorized';
          const isSubcategory = info.row.original.type === 'subcategory';
          const flowType = info.row.original.flowType;
          const enableForecastEditing =
            !isUncategorized && isForecastEditingEnabled && isSubcategory;

          const isSelectedCell = isCellSelected(
            info.row.original,
            meta,
            sums[index]?.interval?.start
          );

          const categoryId = info.row.original.id;
          const flashForecastKey = dayjs(info.getValue()[index]?.interval.start).format('YYYY-MM');
          const flashForecastPreviewAmount =
            categoryId && flashForecastKey && flashForecastPreviewData
              ? flashForecastPreviewData.categories[categoryId]?.[flashForecastKey]
              : undefined;

          const forecast = generateForecastAmount({
            isFirstTimeExperience,
            hasFirstTimeExperieceForecastBeenSet,
            forecast: info.getValue()[index]?.forecast,
          });

          return (
            <BalanceCell
              amount={info.getValue()[index]?.amount}
              categories={[info.row.original]}
              categoryId={info.row.original.id}
              closeSidepanel={closeSidepanel}
              displayForecastPreviewForCategory={displayForecastPreviewForCategory}
              flashForecastPreviewAmount={flashForecastPreviewAmount}
              flowType={flowType}
              forecast={forecast}
              forecastAmount={info.getValue()[index]?.forecastAmount}
              interval={sums[index]?.interval}
              isForecastEditingEnabled={enableForecastEditing}
              isSelectedCell={isSelectedCell}
              isSubcategory={isSubcategory}
              key={`${info.row.original.id}-${sums[index]?.interval?.start}-${info.row.original.flowType}-${isSelectedCell}`}
              onForecastEntryUpdate={onForecastEntryUpdate}
              onNavigateDown={(enterEditMode: boolean): void => {
                onCellNavigation({ rowIndex, columnIndex, direction: 'down', enterEditMode });
              }}
              onNavigateLeft={(enterEditMode: boolean) => {
                onCellNavigation({ rowIndex, columnIndex, direction: 'left', enterEditMode });
              }}
              onNavigateRight={(enterEditMode: boolean): void => {
                onCellNavigation({ rowIndex, columnIndex, direction: 'right', enterEditMode });
              }}
              onNavigateUp={(enterEditMode: boolean): void => {
                onCellNavigation({ rowIndex, columnIndex, direction: 'up', enterEditMode });
              }}
              onViewTransactions={onViewTransactions}
              projectedAmount={sums[index]?.projectedAmount}
              setConfirmFlashForecastForCategory={setConfirmFlashForecastForCategory}
              setDisplayForecastPreviewForCategory={setDisplayForecastPreviewForCategory}
              showProjectedForecast={showProjectedForecast}
            />
          );
        },
      })
    ),
  ] as ColumnDef<CategoriesTableRow>[];

  return {
    tableData,
    columns,
    meta: {
      selectedCategoriesIds,
      selectedIntervalStart,
      selectedFlowType,
    },
  };
};

interface CategoriesTableProps {
  data: CategoriesTableRow[];
  sums: CategoriesTableColumn[];
  isLoading?: boolean;
  numberOfColumns?: number;
  headerLabelKey?: string;
  currentDateIndex?: number;
  bankAccounts?: string | undefined;
  isForecastEditingEnabled?: boolean;
  tbodyRef?: React.RefObject<HTMLTableSectionElement>;
  onCellNavigation: (params: {
    rowIndex: number;
    columnIndex: number;
    direction: 'up' | 'down' | 'left' | 'right';
    enterEditMode: boolean;
  }) => void;
  onForecastEntryUpdate?: () => void;
  showProjectedForecast: boolean;
  flashForecastPreviewData?: CashFlowCategoriesForecastsPreview;
  onRefreshChart: () => void;
}

export function CategoriesTable({
  data,
  sums,
  isLoading = false,
  numberOfColumns = 4,
  headerLabelKey,
  currentDateIndex,
  bankAccounts,
  isForecastEditingEnabled = false,
  tbodyRef,
  onCellNavigation,
  onForecastEntryUpdate,
  showProjectedForecast,
  flashForecastPreviewData,
  onRefreshChart,
  ...props
}: CategoriesTableProps): ReactNode {
  const { formatMessage } = useIntl();
  const abilities = useEmberService('abilities');
  const segment = useEmberService('segment');
  const organizationNavigation = useOrganizationNavigation();
  const { organization } = useOrganizationManager();
  const queryClient = useQueryClient();
  const headerLabel = useMemo(
    () => formatMessage({ id: headerLabelKey }),
    [formatMessage, headerLabelKey]
  );
  const { showToast } = useToast();

  const { isFirstTimeExperience, hasFirstTimeExperieceForecastBeenSet } = useFirstTimeExperience();

  /*
   * BALANCED APPROACH: Use the context for functionality but prevent re-renders
   * by carefully managing dependencies.
   */

  // Get the context once - we'll use it for necessary functionality
  const cashFlowSidePanel = cashFlowSidePanelManager.useCashFlowSidePanel();

  // Store context in a ref so we can access it without causing re-renders
  const contextRef = useRef(cashFlowSidePanel);
  // Update ref value (won't cause re-renders)
  contextRef.current = cashFlowSidePanel;

  // Create stable callback functions for sidepanel operations
  const openSidepanelWith = useCallback((payload: CashFlowSidePanelPayload) => {
    // Always use the latest context from the ref
    contextRef.current.openSidepanelWith(payload);
  }, []);

  const closeSidepanel = useCallback(() => {
    // Always use the latest context from the ref
    contextRef.current.closeSidepanel();
  }, []);

  // Create stable callbacks for forecast operations
  const stableSetDisplayForecastPreview = useCallback((categoryId?: string) => {
    setDisplayForecastPreviewForCategory(categoryId);
  }, []);

  const stableSetConfirmFlashForecast = useCallback((categoryId?: string) => {
    setConfirmFlashForecastForCategory(categoryId);
  }, []);

  // Extract selected context values, but use a separate effect to sync them
  // rather than making the component directly depend on them
  const [contextValues, setContextValues] = useState<{
    selectedInterval: LabelTableInterval | null | undefined;
    selectedCategories: CategoriesTableRow[];
    isFlowSelected: boolean;
    isVisible: boolean;
  }>({
    selectedInterval: undefined,
    selectedCategories: [],
    isFlowSelected: false,
    isVisible: false,
  });

  // Update our local state copy of the context values in an effect
  // This way we get the data but don't re-render when only sidepanel visibility changes
  useEffect(
    () => {
      // Only update if actual important data changed, not just visibility
      if (
        cashFlowSidePanel.selectedInterval !== contextValues.selectedInterval ||
        cashFlowSidePanel.selectedCategories !== contextValues.selectedCategories ||
        cashFlowSidePanel.isFlowSelected !== contextValues.isFlowSelected
      ) {
        setContextValues({
          selectedInterval: cashFlowSidePanel.selectedInterval,
          selectedCategories: cashFlowSidePanel.selectedCategories,
          isFlowSelected: cashFlowSidePanel.isFlowSelected,
          isVisible: cashFlowSidePanel.isVisible,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Deliberately exclude isVisible to prevent re-renders when only visibility changes
    [
      cashFlowSidePanel.selectedInterval,
      cashFlowSidePanel.selectedCategories,
      cashFlowSidePanel.isFlowSelected,
      contextValues.selectedInterval,
      contextValues.selectedCategories,
      contextValues.isFlowSelected,
    ]
  );

  // Use the synchronized values for rendering
  const { mutate: handleForecastAutofill } = useAutofillCashflowForecast({
    onSuccess: async (_, request) => {
      await queryClient.invalidateQueries({ queryKey: ['cashflow-timeseries'] });
      onRefreshChart();
      showToast({
        text: formatMessage(
          { id: 'cash-flow.side-panel.forecast.autofill.success.toast' },
          {
            firstMonth: dayjs().format('MMMM YYYY'),
            lastMonth: dayjs().add(11, 'month').format('MMMM YYYY'),
          }
        ),
        type: 'success',
      });
      setAnimateFlashForecastForCategory(request.category_id);
    },
    onError: () => {
      showToast({
        text: formatMessage({ id: 'toasts.errors.generic' }),
        type: 'error',
      });
    },
  });

  const expandedTableLocalStorageKey = useMemo(
    () => `cashflow-categories-${headerLabel.toLowerCase()}-table`,
    [headerLabel]
  );

  const expandedRowsLocalStorageKey = useMemo(
    () => `cashflow-categories-${headerLabel.toLowerCase()}-table-rows`,
    [headerLabel]
  );

  const cachedExpandedTableState = localStorage.getItem(expandedTableLocalStorageKey);
  const cachedExpandedRowsState = localStorage.getItem(expandedRowsLocalStorageKey);

  const [isTableExpanded, setIsTableExpanded] = useState(
    cachedExpandedTableState ? (JSON.parse(cachedExpandedTableState) as boolean) : true
  );
  const [expandedRows, setExpandedRows] = useState<ExpandedState>(
    cachedExpandedRowsState ? (JSON.parse(cachedExpandedRowsState) as ExpandedState) : true
  );

  const [confirmFlashForecastForCategory, setConfirmFlashForecastForCategory] = useState<
    string | undefined
  >(undefined);

  const [displayForecastPreviewForCategory, setDisplayForecastPreviewForCategory] = useState<
    string | undefined
  >(undefined);

  const [animateFlashForecastForCategory, setAnimateFlashForecastForCategory] = useState<
    string | undefined
  >(undefined);
  const [isFirstTimeExperienceAnimationCompleted, setIsFirstTimeExperienceAnimationCompleted] =
    useState<boolean>(false);

  useEffect(() => {
    localStorage.setItem(expandedTableLocalStorageKey, JSON.stringify(isTableExpanded));
  }, [isTableExpanded, expandedTableLocalStorageKey]);

  useEffect(() => {
    localStorage.setItem(expandedRowsLocalStorageKey, JSON.stringify(expandedRows));
  }, [expandedRows, expandedRowsLocalStorageKey]);

  useEffect(() => {
    const expandedRowsCount = Object.keys(expandedRows).length;
    if (isTableExpanded && expandedRowsCount) {
      segment.track('cash-flow_category_expand');
    }
  }, [expandedRows, segment, isTableExpanded]);

  // Tracks the current selection using CSS classes instead of attributes
  const updateCSSSelection = useCallback(
    (
      selectedCategories: CategoriesTableRow[],
      selectedInterval: LabelTableInterval | undefined
    ) => {
      // Clear previous selection by removing all balance-button-selected classes
      document.querySelectorAll(`.${styles['balance-button-selected']}`).forEach(el => {
        el.classList.remove(styles['balance-button-selected'] ?? '');
      });

      // Set new selection using CSS classes
      if (selectedCategories.length > 0 && selectedInterval) {
        const categoryIds = selectedCategories.map(cat => cat.id).join(',');
        const cellSelector = `button[data-cell-id="${categoryIds}-${selectedInterval.start}"]`;
        const selectedCell = document.querySelector(cellSelector);
        if (selectedCell) {
          selectedCell.classList.add(styles['balance-button-selected'] ?? '');
        }
      }
    },
    []
  );

  // CSS-based highlighting that doesn't cause re-renders
  const handleViewTransactions = useCallback(
    ({
      selectedCategories,
      selectedInterval,
      isFlowSelected,
      tab,
      forecast,
    }: CashFlowSidePanelPayload): void => {
      const isUnlabeled =
        selectedCategories.length === 1 && selectedCategories[0]?.type === 'uncategorized';

      // Track analytics
      segment.track('cash-flow_cell_view-transactions', {
        type: selectedCategories[0]?.type,
        isUnlabeled,
      });

      // Update CSS selection IMMEDIATELY before opening sidepanel
      // This uses DOM manipulation instead of React state to avoid re-renders
      updateCSSSelection(selectedCategories, selectedInterval);

      // Open sidepanel immediately (highlighting is already done via DOM)
      if (abilities.can('view sidepanel cash-flow')) {
        openSidepanelWith({ selectedCategories, selectedInterval, isFlowSelected, tab, forecast });
      } else {
        setTransactionsFilterByCategories(selectedCategories, selectedInterval, organization.id);

        let url = `/transactions`;
        if (bankAccounts) {
          url += `?bank-accounts=${bankAccounts}`;
        }
        void organizationNavigation(url);
      }
    },
    [
      abilities,
      bankAccounts,
      organizationNavigation,
      openSidepanelWith,
      organization.id,
      segment,
      updateCSSSelection,
    ]
  );

  const { tableData, columns, meta } = useMemo(
    () =>
      buildTable(
        data,
        sums,
        isLoading,
        numberOfColumns,
        headerLabel,
        onCellNavigation,
        // PERFORMANCE: Pass empty values for all selection parameters
        // since we're completely disabling selection highlighting
        '',
        0,
        '',
        false,
        closeSidepanel, // stable callback
        stableSetConfirmFlashForecast, // stable callback
        stableSetDisplayForecastPreview, // stable callback
        displayForecastPreviewForCategory,
        isForecastEditingEnabled,
        showProjectedForecast,
        flashForecastPreviewData,
        onForecastEntryUpdate,
        handleViewTransactions,
        isFirstTimeExperience,
        hasFirstTimeExperieceForecastBeenSet
      ),
    [
      data,
      sums,
      isLoading,
      numberOfColumns,
      headerLabel,
      onCellNavigation,
      // PERFORMANCE: Removed all selection dependencies
      closeSidepanel, // stable callback
      stableSetConfirmFlashForecast, // stable callback
      stableSetDisplayForecastPreview, // stable callback
      displayForecastPreviewForCategory,
      isForecastEditingEnabled,
      showProjectedForecast,
      flashForecastPreviewData,
      onForecastEntryUpdate,
      handleViewTransactions,
      isFirstTimeExperience,
      hasFirstTimeExperieceForecastBeenSet,
    ]
  );

  const table = useReactTable({
    data: tableData,
    columns,
    meta,
    state: {
      expanded: expandedRows,
    },
    onExpandedChange: setExpandedRows,
    getCoreRowModel: getCoreRowModel(),
    getSubRows: row => row.subRows,
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: row => Boolean(row.subRows.length),
  });

  const handleManageCategories = (header: Header<CategoriesTableRow, unknown>): void => {
    const title =
      typeof header.column.columnDef.header === 'string'
        ? header.column.columnDef.header.toLowerCase()
        : undefined;
    if (title) {
      segment.track('cash-flow_category_manage');
      void organizationNavigation(`/cash-flow-categories/${title}`);
    }
  };

  // Compute animation state without depending on the sidepanel visibility
  // This ensures animations work but won't cause re-renders when sidepanel opens
  const shouldAnimateForFirstTimeExperience = useMemo(
    () => isFirstTimeExperience && hasFirstTimeExperieceForecastBeenSet,
    [isFirstTimeExperience, hasFirstTimeExperieceForecastBeenSet]
  );

  const flatData = useMemo(() => {
    return data.flatMap(dataRow => {
      if (dataRow.subRows && dataRow.subRows.length > 0) {
        return [dataRow, ...dataRow.subRows];
      }
      return [dataRow];
    });
  }, [data]);

  const generateAnimatedCellProps = useMemo(() => {
    return (rowIndex: number, cellIndex: number): Omit<AnimatedCellProps, 'children'> => {
      const variant = 'applyFlashForecast';
      let rowToAnimate = null;
      let onAnimationComplete;

      if (shouldAnimateForFirstTimeExperience) {
        const animateFromCellIndex = currentDateIndex ? currentDateIndex + 1 : 0;

        rowToAnimate = isFirstTimeExperienceAnimationCompleted ? null : 0;

        onAnimationComplete = (variantName: string) => {
          if (variantName === 'applyFlashForecast') {
            if (!isFirstTimeExperienceAnimationCompleted) {
              setTimeout(() => {
                setIsFirstTimeExperienceAnimationCompleted(true);
              }, ROW_ANIMATION_DURATION_MS);
            }
          }
        };

        return {
          animateFromCellIndex,
          cellIndex,
          isLoading,
          rowIndex,
          rowToAnimate,
          variant,
          onAnimationComplete,
        };
      }

      if (flatData[rowIndex] && displayForecastPreviewForCategory) {
        rowToAnimate =
          displayForecastPreviewForCategory === flatData[rowIndex]?.id ? rowIndex : null;
      }

      if (flatData[rowIndex] && animateFlashForecastForCategory) {
        rowToAnimate = animateFlashForecastForCategory === flatData[rowIndex]?.id ? rowIndex : null;
        onAnimationComplete = (variantName: string) => {
          if (variantName === 'applyFlashForecast') {
            setAnimateFlashForecastForCategory(undefined);
          }
        };
      }

      const animateFutureMonths =
        displayForecastPreviewForCategory || animateFlashForecastForCategory;

      return {
        animateFromCellIndex: animateFutureMonths && currentDateIndex ? currentDateIndex + 1 : 0,
        cellIndex,
        isLoading,
        rowIndex,
        rowToAnimate,
        variant,
        onAnimationComplete,
      };
    };
  }, [
    flatData,
    displayForecastPreviewForCategory,
    animateFlashForecastForCategory,
    currentDateIndex,
    isLoading,
    shouldAnimateForFirstTimeExperience,
    isFirstTimeExperienceAnimationCompleted,
  ]);

  const handleConfirmForecastAutofill = (): void => {
    handleForecastAutofill({ category_id: confirmFlashForecastForCategory ?? '' });
    setConfirmFlashForecastForCategory(undefined);
  };

  return (
    <>
      <table
        aria-busy={isLoading}
        aria-live="polite"
        className={styles.categoriesTable}
        data-test-categories-table
        {...props}
      >
        <colgroup>
          <col />
          {Array.from({ length: numberOfColumns }, (_, index) => (
            <col key={index} />
          ))}
        </colgroup>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => {
                const isFirstColumn = index === 0;
                return (
                  <th
                    className={cx(
                      styles.colHeader,
                      !isTableExpanded && styles.tableCollapsed,
                      currentDateIndex === index && styles.current
                    )}
                    key={header.id}
                    scope="col"
                  >
                    {header.isPlaceholder ? null : (
                      <TableCell
                        align={isFirstColumn ? 'left' : 'center'}
                        data-header-col={index}
                        isLoading={isFirstColumn ? false : isLoading}
                      >
                        {isFirstColumn ? (
                          <div className={styles.colHeaderWrapper}>
                            <div className={styles.colHeaderActions}>
                              <div className={styles.colHeaderActionsLabel}>
                                <button
                                  className={styles.categoriesActionButton}
                                  data-testid="expand-table-button"
                                  onClick={() => {
                                    setIsTableExpanded(!isTableExpanded);
                                  }}
                                  type="button"
                                >
                                  {isTableExpanded ? <ArrowDown /> : <ArrowRight />}
                                </button>
                                <span data-testid="table-title">
                                  {flexRender(header.column.columnDef.header, header.getContext())}
                                </span>
                              </div>

                              <button
                                className={styles.categoriesActionButton}
                                data-testid="manage-categories-button"
                                onClick={() => {
                                  handleManageCategories(header);
                                }}
                                type="button"
                              >
                                <IconCog data-testid="manage-categories-icon" />
                              </button>
                            </div>
                            {showProjectedForecast ? <ProjectedCellHeader /> : null}
                          </div>
                        ) : (
                          flexRender(header.column.columnDef.header, header.getContext())
                        )}
                      </TableCell>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody ref={tbodyRef}>
          {isTableExpanded
            ? table.getRowModel().rows.map((row, rowIndex) => {
                const dataIsSubcategory =
                  row.original.type === 'subcategory' || row.original.type === 'uncategorized';

                const isSelected = isRowSelected(
                  row.original,
                  meta.selectedCategoriesIds,
                  meta.selectedFlowType
                );

                const rowKey = `${row.id}-${
                  isSelected ? 'selected' : 'unselected'
                }-${row.original.type}`;

                const isFirstTimeExperienceAnimated =
                  shouldAnimateForFirstTimeExperience &&
                  !isFirstTimeExperienceAnimationCompleted &&
                  rowIndex === 0 &&
                  !isLoading;

                const isAnimated = [
                  displayForecastPreviewForCategory,
                  animateFlashForecastForCategory,
                ]
                  .filter(Boolean)
                  .some(id => id === row.original.id);

                const RowComponent =
                  isAnimated || isFirstTimeExperienceAnimated ? AnimatedRow : 'tr';

                return (
                  <RowComponent data-is-editable={dataIsSubcategory} key={rowKey}>
                    {row.getVisibleCells().map((cell, cellIndex) => {
                      const isCurrentDate = cellIndex === currentDateIndex;

                      if (cellIndex === 0) {
                        return (
                          <th
                            className={cx(styles.rowHeader, `${isAnimated ? styles.animated : ''}`)}
                            data-testid="row-header"
                            key={cell.id}
                            scope="row"
                          >
                            <TableCell
                              align="left"
                              data-col={cellIndex}
                              data-row={rowIndex}
                              isLabel
                              isLoading={isLoading}
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </TableCell>
                          </th>
                        );
                      }

                      const intervalStart = sums[cellIndex - 1]?.interval?.start;
                      const isSelectedCell = isCellSelected(row.original, meta, intervalStart);
                      const keyId = `${cell.id}-${intervalStart}-${row.original.flowType}-${isSelectedCell}`;

                      const animatedCellProps = generateAnimatedCellProps(rowIndex, cellIndex);

                      if (dataIsSubcategory) {
                        const cellValues = cell.getValue() as CategoriesTableColumn[];
                        const amount = cellValues[cellIndex - 1]?.amount?.value;

                        return (
                          <td
                            className={`caption ${styles.rowCell} ${isAnimated ? styles.animated : ''} ${isCurrentDate ? styles.current : ''}`}
                            data-col-index={cellIndex}
                            key={keyId}
                            role="gridcell"
                          >
                            <AnimatedCell
                              {...animatedCellProps}
                              data-testid={`animated-cell-${rowIndex}-${cellIndex}`}
                            >
                              <MemoizedTableCell
                                amount={amount}
                                confirmFlashForecastForCategory={confirmFlashForecastForCategory}
                                data-col={cellIndex}
                                data-row={rowIndex}
                                displayForecastPreviewForCategory={
                                  displayForecastPreviewForCategory
                                }
                                hasFirstTimeExperieceForecastBeenSet={
                                  hasFirstTimeExperieceForecastBeenSet
                                }
                                isLoading={isLoading}
                                showProjectedForecast={showProjectedForecast}
                              >
                                {flexRender(cell.column.columnDef.cell, {
                                  ...cell.getContext(),
                                  columnIndex: cellIndex,
                                  rowIndex,
                                })}
                              </MemoizedTableCell>
                            </AnimatedCell>
                          </td>
                        );
                      }

                      return (
                        <td
                          className={`caption ${styles.rowCell} ${isAnimated ? styles.animated : ''} ${isCurrentDate ? styles.current : ''}`}
                          data-col-index={cellIndex}
                          key={keyId}
                          role="gridcell"
                        >
                          <AnimatedCell
                            {...animatedCellProps}
                            data-testid={`animated-cell-${rowIndex}-${cellIndex}`}
                          >
                            <TableCell
                              data-col={cellIndex}
                              data-row={rowIndex}
                              isLoading={isLoading}
                            >
                              {flexRender(cell.column.columnDef.cell, {
                                ...cell.getContext(),
                                columnIndex: cellIndex,
                                rowIndex,
                              })}
                            </TableCell>
                          </AnimatedCell>
                        </td>
                      );
                    })}
                  </RowComponent>
                );
              })
            : null}
        </tbody>
      </table>
      <FlashForecastConfirmationPopup
        isOpen={Boolean(confirmFlashForecastForCategory)}
        onPopupCancel={(): void => {
          setConfirmFlashForecastForCategory(undefined);
        }}
        onPopupConfirm={handleConfirmForecastAutofill}
      />
    </>
  );
}
