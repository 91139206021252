import type { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Disclaimer } from '@repo/design-system-kit';
import { EARLY_REPAYMENT_ERRORS } from 'qonto/constants/financing';
import styles from './error-disclaimer.strict-module.css';

interface ErrorDisclaimerProps {
  error: string;
}

/**
 * A component that displays an error message for {@link EARLY_REPAYMENT_ERRORS} in a disclaimer block.
 */
export function ErrorDisclaimer({ error }: ErrorDisclaimerProps): ReactElement {
  let message;

  switch (error) {
    case EARLY_REPAYMENT_ERRORS.INSUFFICIENT_BALANCE:
      message = (
        <FormattedMessage id="financing.pay-later.early-repayment.summary.disclaimer.insufficient-funds" />
      );
      break;
    case EARLY_REPAYMENT_ERRORS.DEBIT_BLOCKED:
      message = (
        <FormattedMessage id="financing.pay-later.early-repayment.summary.disclaimer.debit-blocked" />
      );
      break;
    default:
      break;
  }

  return (
    <Disclaimer.Block className={styles.disclaimer} level="error">
      {message}
    </Disclaimer.Block>
  );
}
