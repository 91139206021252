import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import { useMemo } from 'react';
import { FIRST_TIME_EXPERIENCE_STATES } from 'qonto/services/cash-flow-manager';

interface FirstTimeExperience {
  isFirstTimeExperience: boolean;
  hasFirstTimeExperieceForecastBeenSet: boolean;
  hasFirstTimeExperieceForecastBeenStarted: boolean;
}

export const useFirstTimeExperience = (): FirstTimeExperience => {
  const abilities = useEmberService('abilities');
  const cashFlowManager = useEmberService('cash-flow-manager');

  return useMemo(() => {
    const isFirstTimeExperience = Boolean(
      abilities.cannot('fully interact with forecast cash-flow')
    );

    const hasFirstTimeExperieceForecastBeenSet =
      cashFlowManager.firstTimeExperienceState === FIRST_TIME_EXPERIENCE_STATES.FORECAST_SET;

    const hasFirstTimeExperieceForecastBeenStarted =
      cashFlowManager.firstTimeExperienceState !== FIRST_TIME_EXPERIENCE_STATES.NOT_STARTED;

    return {
      isFirstTimeExperience,
      hasFirstTimeExperieceForecastBeenSet,
      hasFirstTimeExperieceForecastBeenStarted,
    };
  }, [abilities, cashFlowManager.firstTimeExperienceState]);
};
