import { useEffect, useState, type ReactNode } from 'react';
import { BaseTable } from '@repo/domain-kit/shared';
import { LoadingTransactionRow } from '@repo/domain-kit/cashflow';
import {
  type UpcomingTransaction,
  type UpcomingTransactionsResponse,
} from 'qonto/react/hooks/use-upcoming-transactions';
import { columns } from './columns.tsx';
import styles from './styles.strict-module.css';

interface UpcomingTransactionsTableProps {
  data: UpcomingTransactionsResponse | undefined;
  isPending: boolean | undefined;
  onEmptyStateChange: (isEmpty: boolean) => void;
  selectedFlowKey?: string;
  handleHover: (amount: string | null) => void;
  handleEditUpcomingTransaction: (upcomingTransaction: UpcomingTransaction) => void;
  handleUpdatedDateForPreviewLine: (
    date: string | null,
    amount: string | null,
    originalDate: string | null,
    side: string | null
  ) => void;
}

export function UpcomingTransactionsTable({
  data,
  isPending,
  onEmptyStateChange,
  selectedFlowKey,
  handleEditUpcomingTransaction,
  handleUpdatedDateForPreviewLine,
  handleHover,
}: UpcomingTransactionsTableProps): ReactNode {
  const [filteredTransactions, setFilteredTransactions] = useState<UpcomingTransaction[]>([]);

  useEffect(() => {
    if (data?.upcoming_transactions) {
      let filtered = data.upcoming_transactions;

      if (selectedFlowKey === 'inflows') {
        filtered = data.upcoming_transactions.filter(transaction => transaction.side === 'credit');
      } else if (selectedFlowKey === 'outflows') {
        filtered = data.upcoming_transactions.filter(transaction => transaction.side === 'debit');
      }

      setFilteredTransactions(filtered);
    }
  }, [data?.upcoming_transactions, selectedFlowKey]);

  useEffect(() => {
    if (!isPending) {
      const isEmpty = !filteredTransactions.length;
      onEmptyStateChange(isEmpty);
    }
  }, [filteredTransactions, isPending, onEmptyStateChange]);

  return (
    <div className={styles['table-container']}>
      <BaseTable
        columns={columns}
        data={filteredTransactions}
        enablePagination={false}
        enableRowHover
        isLoading={isPending}
        loadingComponent={LoadingTransactionRow()}
        meta={{
          handleEditUpcomingTransaction,
          handleHover,
          handleUpdatedDateForPreviewLine,
        }}
        pageSize={25}
        pageSizes={[25, 50, 100]}
        total={filteredTransactions.length}
      />
    </div>
  );
}
